import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { deleteCategory, deleteProvider } from "../../apiservices";
import { toast } from "react-toastify";
const Btn = styled(Button)({
  textTransform: "none",
  background: "#EEF4FF",
  color: "#EE1C25",
  "&:hover": {
    color: "#000",
    background: "#EE1C25",
  },
});

export function CustomToolbar() {
  return (
    <GridToolbar
      sx={{
        "& .MuiButtonBase-root": {
          color: 'black'
        }
      }}
    />
  );
}

export default function DataTable({
  editProvider,
  data,
  handleCheck,
  getData,
}) {
  if (data) {
    data.forEach((element) => {
      element["id"] = element.id;
    });
  }
  const columns = [
    { field: "brand_name", headerName: "Brand Name", width: 200 },
    { field: "category_name", headerName: "Category Name", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <>
          <Btn
            title="Edit"
            variant="contained"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "#eed202", color: "white" }}
            onClick={() => edit(params.row)}>
            <EditIcon fontSize="small" />
          </Btn>
          <Btn
            title="Delete"
            sx={{ width: '30px', height: '30px', minWidth: '30px', mr: '5px', padding: 0, background: "red", color: "white" }}
            variant="contained"
            onClick={() => deleteRow(params.row)}>
            <DeleteIcon fontSize="small" />
          </Btn>
        </>
      ),
    },
  ];

  const rows = data;
  const edit = (params) => {
    editProvider(params);
    handleCheck();
  };
  const deleteRow = async (params) => {
    /* eslint-disable no-restricted-globals */
    if (confirm(`Are you sure to delete "${params.brand_name}"`)) {
      let res = await deleteCategory(params.id);
      if (res.success) {
        toast.error("Category deleted successfully");
        getData();
      }
    }
  };
  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[20, 50]}
        components={{
          Toolbar: CustomToolbar,
        }}
      // checkboxSelection
      />
    </div>
  );
}
