import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../dashboard/dashboard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DataTable from "./PurchaseTable";
import AddPurchase from "./AddPurchase";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Back } from "../../assets/Svg";
import PrintIcon from "@mui/icons-material/Print";
import ReactToPrint from "react-to-print";
import { getPurchases, getSupplier } from "../../apiservices";
import BillPreview from "./BillPreview";

const Btn = styled(Button)({
  minWidth: "126px",
  height: "36px",
  padding: "4px",
  borderRadius: "4px",
  textTransform: "none",
  background: "#FFFFFF",
  color: "#EE1C25",
  marginRight: 10,
  "&:hover": {
    background: "#F6F6F6",
  },
  "&:active": {
    background: "#FFFFFF",
  },
});
const Purchase = () => {
  const ViewBillRef = useRef();
  const [state, setState] = useState({
    addItem: false,
    viewBill: false,
    tableArr: [],
    viewBillModal: false,
    billData: {},
    rowData: [],
    purchasesData: {},
    billsLength: 0,
    viewBillData: {},
    itemsArr: [],
    searchData: [],
    customData: {},
    providerData: [],
    supplierBalance: 0,
    arr: [],
  });
  const addPurchases = (data = "") => {
    setState((prev) => ({
      ...prev,
      addItem: !prev.addItem,
    }));
    if (data.id) {
      setState((prev) => ({
        ...prev,
        itemsArr: data.savePurchase
          ? [...data?.items, ...data?.savePurchase]
          : data?.items,
        customData: data,
        arr: data.items,
      }));
    }
  };
  const viewBills = async (data = "") => {
    getProvidersData();
    setState((prev) => ({
      ...prev,
      viewBill: !prev.viewBill,
    }));
    console.log("data", data)
    if (data) {
      setState((prev) => ({
        ...prev,
        viewBillData: data,
        // tableArr: data.savePurchase
        //   ? [...data?.items, ...data?.savePurchase]
        //   : data?.items,
      }));
    }
  };
  const getData = (data) => {
    setState((prev) => ({ ...prev, tableArr: data }));
  };
  const viewBillModal = (data = "") => {
    setState((prev) => ({
      ...prev,
      viewBillModal: !prev.viewBillModal,
      billData: data,
    }));
  };
  const getProvidersData = async () => {
    let obj = {};
    let res = await getSupplier(obj);
    if (res.success) {
      setState((prev) => ({ ...prev, providerData: res.data }));
    }
  };
  const getBillData = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));
    let obj = {}
    let res = await getPurchases(obj);
    if (res.success) {
      res.data.map((x) => {
        if(x.voucher_no.length == 1){
          x.show_voucher_no = `Purchase#00${x.voucher_no}`
        }
        else if(x.voucher_no.length == 2){
          x.show_voucher_no = `Purchase#0${x.voucher_no}`
        }
        else{
          x.show_voucher_no = `Purchase#${x.voucher_no}`
        }
      })
      setState((prev) => ({
        ...prev,
        rowData: res.data,
      }))
    }
    else {
      setState((prev) => ({
        ...prev,
        rowData: [],
      }))
    }
  };
  const ComponentToPrint = React.forwardRef((props, ref) => (
    <Box spacing={3} sx={{ mt: 2 }} ref={ref}>
      <Stack>
        <BillPreview
          data={state.viewBillData}
          tableArr={state.tableArr}
          supplierBalance={state.supplierBalance}
        />
      </Stack>
    </Box>
  ));
  const cancelPurchase = () => {
    setState((prev) => ({
      ...prev,
      addItem: false,
      viewBill: false,
      viewBillModal: false,
    }));
  };
  useEffect(() => {
    getBillData();
    getProvidersData();
  }, []);
  const updateData = (data) => {
    setState((prev) => ({ ...prev, rowData: data }));
  };
  return (
    <Stack sx={{ paddingLeft: 2, paddingRight: 2 }}>
      {state.viewBill ? (
        <>
          <Layout justifyContent="end" alignItems={"center"} direction={"row"}>
            <Stack direction={"row"}>

              <ReactToPrint
                trigger={() => (
                  <Btn>
                    <PrintIcon fontSize="small" sx={{ mx: 1 }} /> Print
                  </Btn>
                )}
                // onBeforePrint={printBill}
                content={() => ViewBillRef.current}
              />
              <Btn onClick={viewBills}>
                <Back sx={{ mx: 1 }} /> Back
              </Btn>
            </Stack>
          </Layout>
          <Stack id="content-to-print">
            <ComponentToPrint ref={ViewBillRef} />
          </Stack>
        </>
      ) : state.addItem === false ? (
        <>
          <Layout
            justifyContent="space-between"
            alignItems={"center"}
            direction={"row"}>
            <Typography
              sx={{
                color: "#000",
                fontSize: "24px",
                fontWeight: 700,
              }}></Typography>
            <Typography
              sx={{ color: "#000", fontSize: "24px", fontWeight: 700 }}>
              Purchases
            </Typography>
            <Btn onClick={addPurchases}>
              <AddCircleIcon fontSize="small" sx={{ mx: 1 }} /> Add new purchase
            </Btn>
          </Layout>
          <Stack sx={{ padding: "20px" }}>
            <DataTable
              addPurchases={addPurchases}
              view={(data) => viewBills(data)}
              rowData={state.rowData !== undefined && state.rowData}
              getBillData={getBillData}
              providerData={state.providerData}
            />
          </Stack>
        </>
      ) : (
        <AddPurchase
          addPurchases={(data) => viewBillModal(data)}
          view={viewBills}
          getTabelData={(data) => getData(data)}
          billNo={`ZR_00${state.rowData.length + 1}`}
          itemsArr={state.itemsArr}
          cancelPurchase={cancelPurchase}
          customData={state.customData}
          getBillData={getBillData}
          rowData={state.rowData}
          items={state.arr}
        />
      )}
      {/* <CustomDialogue
        open={state.viewBillModal}
        handleClose={viewBillModal}
        header={
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack direction={"row"}>
              <Btn onClick={printToPDF}>
                <PictureAsPdfIcon fontSize="small" sx={{ mx: 1 }} /> PDF
              </Btn>

              <ReactToPrint
                trigger={() => (
                  <Btn>
                    <PrintIcon fontSize="small" sx={{ mx: 1 }} /> Print
                  </Btn>
                )}
                onBeforePrint={printBill}
                // content={() => componentRef.current}
              />
              <Btn onClick={viewBillModal}>
                <Back sx={{ mx: 1 }} /> Back
              </Btn>
            </Stack>
          </Stack>
        }
        content={
          <Stack id="content-to-print">
            <ComponentToPrint ref={componentRef} />
          </Stack>
        }
      /> */}
    </Stack>
  );
};

export default Purchase;
