import { Stack, Typography } from "@mui/material"
import Logo from "../../assets/erorex-Logo.png";

const ClientInfo = () => {
  return (
    <Stack
      sx={{ mb: 2, mt: 1 }}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"end"}
      spacing={3}>
      <img src={Logo} alt="logo" width={"250"} height={"100"} />

      <Stack sx={{mt: "-10px"}}>
        <Typography sx={{ fontSize: "22px", fontWeight: "bold" }}>
          ESK Communication
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>
          First Floor, Idrees Plaza, Near Bank Al-Falah,
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>
          Liaqat Bagh Road, Rawalpindi
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>
          Email : saddamesk@gmail.com
        </Typography>
        <Typography sx={{ fontSize: "14px" }}>
          Saddam Khan : 0346-6838338
        </Typography>
      </Stack>
    </Stack>
  )
}

export default ClientInfo