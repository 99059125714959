import React, { useState } from "react";
import { CustomField } from "../client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
function Search({ data, updateData, place1, place2 }) {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [state, setState] = useState({
    search: "",
  });
  const handleChange = (e) => {
    let arr = data;
    let { value } = e.target;
    setState((prev) => ({ prev, search: value }));
    let filtered = arr.filter(
      (item) =>
        (item?.paymentNumber &&
          item?.paymentNumber.toLowerCase().includes(value.toLowerCase())) ||
        (item?.payer &&
          item?.payer.toLowerCase().includes(value.toLowerCase())) ||
        (item?.billNumber &&
          item?.billNumber.toLowerCase().includes(value.toLowerCase())) ||
        (item?.clientName &&
          item?.clientName.toLowerCase().includes(value.toLowerCase())) ||
        (item?.providersName &&
          item?.providersName.toLowerCase().includes(value.toLowerCase())) ||
        (item?.brand &&
          item?.brand.toLowerCase().includes(value.toLowerCase())) ||
        (item?.modelName &&
          item?.modelName.toLowerCase().includes(value.toLowerCase())) ||
        (item?.providersName &&
          item?.providersName.toLowerCase().includes(value.toLowerCase())) ||
        (item?.bank_name &&
          item?.bank_name.toLowerCase().includes(value.toLowerCase())) ||
        (item?.category &&
          item?.category.toLowerCase().includes(value.toLowerCase())) ||
        (item?.city && item?.city.toLowerCase().includes(value.toLowerCase()))
    );
    console.log(filtered);
    updateData(filtered);
  };
  return (
    <div>
      {" "}
      <CustomField
        size="small"
        id="outlined-select-currency"
        label={`${place1} ${place2}`}
        name="search"
        value={state.search}
        onChange={handleChange}
        sx={{
          background: "#F6F6F6",
          mb: 2,
          width: fullScreen ? "250px" : "500px",
          "& label.Mui-focused": {
            color: "#EE1C25",
          },
        }}
      />
    </div>
  );
}

export default Search;
