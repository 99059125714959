import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

// import Logo from "../../assets/White-Background.jpeg";
import styled from "@emotion/styled";
import AdminLogin from "./AdminLogin";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
export const Btn = styled(Button)({
  textTransform: "none",
  height: "30px",
  fontWeight: 400,
  padding: "0px 20px",
  fontFamily: "Raleway",
});

function Index() {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [state, setState] = useState({
    loginType: "admin",
    regType: "login",
  });

  const changeType = (type, input) => {
    setState((prev) => ({ ...prev, [type]: input }));
  };

  return (
    <Stack direction="row" sx={{ minHeight: "100vh" }}>
      <>
        {state.regType === "login" ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ background: "#F0F0F0", minHeight: "100vh", width: "100%" }}>
            <Typography
              sx={{ fontWeight: "bold", color: "#EE1C25", fontSize: "24px" }}>
              Welcome Back !
            </Typography>
            <Typography sx={{ color: "#B8B8B8", mt: 1 }}>
              Please login to continue
            </Typography>
            <Stack
              direction="row"
              sx={{
                marginTop: "40px",
                borderBottom: 1,
                borderColor: "#EE1C25",
                borderRadius: "5%",
              }}>
              {/* <Btn
                onClick={() => changeType("loginType", "admin")}
                sx={{
                  background: "#EE1C25",
                  color: "#000",
                  ":hover": {
                    background: "#EE1C25",
                  },
                }}>
                Admin Login
              </Btn> */}
            </Stack>
            <AdminLogin
              loginType={state.loginType}
              changeType={(type, data) => changeType(type, data)}
            />
            {/* <img src={MiniLogo} alt="" height={"32px"} width={"130px"} /> */}
          </Stack>
        ) : (
          <>
            {/* <Register regType={(type, data) => changeType(type, data)} /> */}
          </>
        )}
        {!fullScreen && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "#fff",
              minHeight: "100vh",
              width: "100%",
            }}>
            {/* <img src={Logo} alt="" width={"500"} height={"500"} /> */}
          </Stack>
        )}
      </>
    </Stack>
  );
}

export default Index;
